.container {
  display: block;
  align-items: center;
  padding: 1rem 0px;
  height: 100%;
  border-left: none;

  .list {
    min-width: 17.14rem;
  }
}

.smallView {
  .arrow {
    left: 2.5rem !important;
  }
}

.item {
  display: grid;

  &.active {
    span {
      font-weight: bold;

      i {
        color: #46b0c6;
      }
    }

    &::after {
      content: "";
      width: 6px;
      height: fit-content;
      background: #46b0c6;
      position: absolute;
      height: 40px;
    }
  }
}

.active + .subItem:not(.hidden) {
  display: grid !important;
  > a {
    padding-left: 67px;
  }
}

.subItem {
  display: none;
}

.icon {
  font-size: 1.3rem;
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
}

.subItemLink {
  transition: background 1s;
  padding: 8px 0;
}

.arrow {
  left: 16rem;
  height: 100%;
  top: 0px;
  position: absolute;
}

.arrowIcon {
  margin: auto !important;
  border-radius: 100%;
  font-size: 24px;
}
