.container {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  // background-color: rgba(0, 0, 0, 0.5);
  z-index: 111111111111111111111111111111111111111;
  display: flex;
  align-items: center;
  text-align: center;
}
