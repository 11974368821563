.wrapper {
  position: relative;

  .clearIcon {
    display: none;
    cursor: pointer;
  }

  &:hover {
    .clearIcon {
      display: block;
    }

    .processingIcon,
    .failedIcon {
      display: none;
    }
  }
}
