.navLink {
  // color: #299acb;
  color: #778083;
  &.active {
    color: #1c4f5a !important;
    border-bottom: 2px solid #1c4f5a;
  }
  &.active:hover {
    color: #1c4f5a !important;
    border-bottom: 2px solid #1c4f5a;
  }
}

.navLink:hover {
  color: #46b0c6;
}
