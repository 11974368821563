.datePicker {
  width: 100%;
  min-height: 2.625rem;
  border-radius: 0.25rem;
  cursor: pointer;
  :global {
    .ant-picker-input {
      width: 90px !important;
    }
    .ant-picker-active-bar {
      display: none;
    }
    .ant-picker-suffix {
      width: calc(100% - 200px);
      text-align: right;
    }
  }
}
