.container {
  color: #0a3637;
  width: 6.25rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 0.25rem;
  display: flex;
  padding: 12px;
  cursor: pointer;
  border: 1px solid #9b9ca5;
}

.text {
  font-size: 13px;
}

.icon > svg {
  font-size: 10px;
  margin-left: 6px;
}
