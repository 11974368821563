.container {
  border: 1px solid #e6f0f3;
  @media screen and (min-width: 640px) {
    height: 160px;
    width: 160px;
  }
  height: 160px;
  width: 120px;
  border-radius: 4px;
  transition: background 1s;
  padding: 0px;

  :global {
    .ant-upload-picture-card-wrapper {
      display: flex;
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.02);

    .uploadPhoto {
      opacity: 1;
    }
    .buttons {
      visibility: unset;
    }
  }
}

.image {
  transition: height 1s;
  height: 160px;
}

.default {
  display: flex;
  align-items: center;
  text-align: center;

  :global {
    .ant-upload-picture-card-wrapper {
      margin-top: 95px;
      // width: 160px;
      // position: absolute !important;
      width: 160px;
      position: sticky;
      width: 100%;
      margin-left: -100%;
    }
  }

  .image {
    height: 60px;
  }
  // &:hover {
  //   .image {
  //     height: 60px;
  //   }
  // }
}

.uploadContainer {
  position: absolute !important;
}

.uploadPhoto {
  font-weight: 500;
  color: #46b0c6;
  font-size: 13px;
  // opacity: 0;
  transition: opacity 0.2s;
}

.buttons {
  // position: absolute;
  display: inline-grid;
  padding: 20px;
  // width: 160px;
  visibility: hidden;
  width: 160px;
  position: sticky;
  width: 100%;
  margin-left: -100%;
}

.change {
  color: #ffffff;
}
