.divider {
  margin-top: 1.5rem;
  margin-bottom: 4px;
  color: #778083;
  text-align: center;
  font-size: 12px;
  position: relative;
}
.divider::before {
  content: '';
  display: block;
  width: 45%;
  height: 1px;
  background: #e2e4e4;
  left: 0;
  top: 50%;
  position: absolute;
}
.divider::after {
  content: '';
  display: block;
  width: 45%;
  height: 1px;
  background: #e2e4e4;
  right: 0;
  top: 50%;
  position: absolute;
}
.social-btn {
  height: 38px;
  line-height: 38px;
}
