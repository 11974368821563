.filterButton {
  border: none;
  border-color: #cbe0e6;
  min-height: 34px !important;
  // border: 1px solid transparent;
}

.filterButton:hover,
.filterButton:active,
.filterButton:focus {
  border-color: #cbe0e6;
}

.caret {
  font-size: 8px;
}

.menuButtonOpen {
  background-color: #e4f3f7 !important;
  border-bottom: none !important;
  border-bottom-left-radius: 0;
}

.filterMenu {
  border: none;
}

.filterIcon {
  color: #46b0c6;
}
