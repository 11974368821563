.container {
  :global {
    .ant-radio-checked .ant-radio-inner {
      border-color: #46b0c6 !important ;
    }

    .ant-radio-checked .ant-radio-inner:after {
      background-color: #46b0c6;
    }

    .ant-radio:hover .ant-radio-inner {
      border-color: #46b0c6;
    }

    .ant-radio-checked .ant-radio-inner:focus {
      border-color: #46b0c6;
    }
  }
}
