.timePicker {
  width: 100%;
  min-height: 2.625rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.timePicker:hover {
  border-color: #cbe0e6;
}
