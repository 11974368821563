.row {
  &:hover {
    td {
      overflow: hidden;
    }
    > td:not(.removeHighlightOnHover) {
      background: #e6f0f376;
    }
    :global {
      .display-hover {
        display: block;
      }
      .display-right-hidden {
        margin-right: 0px;
      }
    }
  }

  :global {
    .display-hover {
      display: none;
    }
    .display-right-hidden {
      margin-right: -200px;
      transition: margin-right 0.5s;
    }
  }
}
