.slideContent {
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  grid-area: 1 / -1;

  transform-style: preserve-3d;
  transform: perspective(1000px) translateX(calc(110% * var(--offset)))
    rotateY(calc(-45deg * var(--dir)));

  @media (min-width: 1280px) {
    width: 30vw;
  }
  width: 40vw;
}

.slideContent:hover {
  filter: brightness(1.05);
}

.slideContentText {
  @media (max-height: 600px) {
    font-size: large;
    > span {
      font-size: large;
    }
  }
}
