.pill {
  border-radius: 4px;
  padding: 2px 7px 2px 5px;

  .removePill {
    &:hover {
      color: #0a3637;
    }
  }

  &.danger {
    .removePill {
      color: #c83636;
      &:hover {
        color: #d73535;
      }
    }
  }
}

.spanRemove {
  margin-right: -3px;
}

.input {
  &:active,
  &:focus {
    outline: 0;
  }
  // &::placeholder {
  //   color: #bfbfbf;
  // }
}

.error {
  border-color: #c83636;
}

.required {
  border-top: 25px solid #cbe0e6;
  border-left: 25px solid transparent;
  top: 0px;
  right: 0px;
  position: absolute;

  &.error {
    border-top-color: #c83636;
  }

  > .icon {
    position: absolute;
    top: -25px;
    right: 0px;
    font-size: 7px;
    color: #46b0c6;

    &.error {
      color: #ffffff;
    }
  }
}

.wrapper {
  max-width: 98%;
  width: 98%;
}

:global {
  input[disabled] {
    background: transparent;
  }
}
