.category:hover,
.redeemable:hover {
  background-color: #e6f0f3;
  .icon {
    visibility: visible;
  }
}

.icon {
  padding: 0;
  visibility: hidden;
}

.iconCat {
  padding: 16px;
}

.redeemableContainer {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
}
